import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/home-server",
  "title": "Home Server",
  "started": "2020-06-11T00:00:00.000Z",
  "published": "2020-06-25T00:00:00.000Z",
  "backgroundImage": "servers",
  "tags": ["Infrastructure", "Ansible", "LXD"],
  "layoutClass": "home-server",
  "thumbnail": "blog/2020-06-11-home-server/thumbnail.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Image className="image-right" alt="Supermicro" path="blog/2020-06-11-home-server/supermicro.jpg" mdxType="Image" />
    <p>{`My first home server back in 2014, was an old cast-off supermicro which had been used as a router, for many years at work.
I hand-crafted into LXC containers on a 64GB SSD and a 1.5TB spinning rust drive (also rejects from work) to install
various services on.
The server lasted a few years until it's SSD died - Hands up if you saw that coming...
Luckily anything important was stored on the spinning rust drive.`}</p>
    <p>{`Writing this post reminds me of the fun I had with that machine - including the times when I came home in the summer and
it was strangely quiet - because it's cooling fan had ceased.
Ah, they were the days.`}</p>
    <p>{`When it came to replacing the server, I remember wishing I had notes about how I set services up, what decisions had I
made during the hand-crafting process years before.`}</p>
    <h2>{`Hardware`}</h2>
    <Image className="image-right" alt="HP ProLiant ML110 G6" path="blog/2020-06-11-home-server/proliant.jpg" mdxType="Image" />
    <p>{`Continuing the trend of using cast-off hardware, I was given a surplus workstation which a colleague had taken home some
time before.
So it's been a cast off twice over!`}</p>
    <p>{`For those playing along at home, it is an
`}<a parentName="p" {...{
        "href": "https://support.hpe.com/hpesc/public/docDisplay?docId=emr_na-c01926955"
      }}>{`HP ProLiant ML110 G6`}</a>{`.
Among it's stunning features, it includes:`}</p>
    <ul>
      <li parentName="ul">{`a `}<a parentName="li" {...{
          "href": "https://ark.intel.com/content/www/us/en/ark/products/43230/intel-pentium-processor-g6950-3m-cache-2-80-ghz.html"
        }}>{`Intel G6950`}</a>{` -
two cores running at 2.8GHz`}</li>
      <li parentName="ul">{`16GB of DDR3 memory`}</li>
      <li parentName="ul">{`2x 128GB SSDs in a RAID 1 configuration for the OS and containers`}</li>
      <li parentName="ul">{`a Dell PERC H200 (a Dell-branded LSI Logic hardware raid controller)`}</li>
      <li parentName="ul">{`4x 2TB SAS spinning rust drives arranged in RAID 10 configuration`}</li>
    </ul>
    <p>{`Perhaps one day I'll follow the hardware set up posted by people online who have 5 to 10 u of rack-mounted
servers.
Presumably costing quite a sum to purchase, and equally scary cost to power.`}</p>
    <h2>{`Configuration`}</h2>
    <p>{`Following my revelation that it might be good to have some sort of documentation about how a service was set up, I
looked into options for some sort of configuration management.
I had a play with `}<a parentName="p" {...{
        "href": "https://puppet.com/"
      }}>{`Puppet`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.chef.io/"
      }}>{`Chef`}</a>{` for a while, but in the end decided to
use `}<a parentName="p" {...{
        "href": "https://www.ansible.com/"
      }}>{`Ansible`}</a>{` as it was the tool that I'd be using with a change of role at work, so it seemed
like a good way to learn it.`}</p>
    <h3>{`Networks`}</h3>
    <p>{`I think I may have gone overboard with my home network set up.
There are three main networks, each NATed to my public IP address:`}</p>
    <ul>
      <li parentName="ul">{`LXD - the network that all LXD containers on this machine are added to`}</li>
      <li parentName="ul">{`Home - the main home network used by the phones and computers of the people living in the house`}</li>
      <li parentName="ul">{`IoT - a somewhat untrusted network for IoT devices which I don't necessarily trust being on the main network`}</li>
    </ul>
    <p>{`As these networks go through the same switch I have three VLANs set up:`}</p>
    <ul>
      <li parentName="ul">{`one for the ISP provided modem`}</li>
      <li parentName="ul">{`another for the home network`}</li>
      <li parentName="ul">{`and the last for the IoT network`}</li>
    </ul>
    <p>{`My wireless access point supports assigning a VLAN to SSIDs.`}</p>
    <p>{`As well as these networks, I also use `}<a parentName="p" {...{
        "href": "https://www.wireguard.com/"
      }}>{`WireGuard`}</a>{` for a couple of VPNs:`}</p>
    <ul>
      <li parentName="ul">{`A management VPN to allow my workstations to connect to the containers on various machines`}</li>
      <li parentName="ul">{`An inter-server private network to allow remote machines to communicate privately over the public internet`}</li>
    </ul>
    <p>{`Overall network config is defined in a variable in my Ansible plays.`}</p>
    <h3>{`LXD containers`}</h3>
    <p>{`The OS is currently Ubuntu 18.04, and is carved into several LXD containers, one per service.
Services I currently run on this machine:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dani-garcia/bitwarden_rs"
        }}>{`Bitwarden RS`}</a>{` - A Rust implementation of the
`}<a parentName="li" {...{
          "href": "https://bitwarden.com/"
        }}>{`Bitwarden`}</a>{` password manager - read the blog post about
`}<a parentName="li" {...{
          "href": "/blog/bitwarden-rs"
        }}>{`switching from Bitwarden to Bitwarden RS`}</a></li>
      <li parentName="ul">{`A DHCP server`}</li>
      <li parentName="ul">{`DNSS - DNS over HTTPS`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://drone.io/"
        }}>{`Drone CI`}</a>{` - A continuous integration service which integrates nicely with Gitea`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gitea.io/"
        }}>{`Gitea`}</a>{` - Self-hosted Git service (think a self-hosted Github or a light-weight Gitlab)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://grafana.com/"
        }}>{`Grafana`}</a>{` - Pretty monitoring dashboards`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/go-graphite"
        }}>{`Go Graphite`}</a>{` - An implementation of the `}<a parentName="li" {...{
          "href": "https://graphiteapp.org/"
        }}>{`Graphite`}</a>{` Time
Series DataBase (TSDB) written in Go`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.home-assistant.io/"
        }}>{`Home Assistant`}</a>{` - Open source home automation`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jellyfin.org/"
        }}>{`Jellyfin`}</a>{` - A self-hosted media system, forked from Emby when Emby changed it's licence and
closed it's source - Read about the `}<a parentName="li" {...{
          "href": "/blog/jellyfin-electron"
        }}>{`Electron app I wrote for Jellyfin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mysql.com/"
        }}>{`MySQL`}</a>{` - You've heard of MySQL, right?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nextcloud.com/"
        }}>{`Nextcloud`}</a>{` - A self-hosted cloud system - I mostly use it to exfiltrate photos from my phone
to my home server`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nginx.org/"
        }}>{`Nginx`}</a>{` - while there are a few instances of Nginx on the server, this container acts as a reverse
proxy for the other services to be available outside the home network using `}<a parentName="li" {...{
          "href": "https://letsencrypt.org/"
        }}>{`Let's Encrypt`}</a>{`
for TLS certificates`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pi-hole.net/"
        }}>{`Pi-hole`}</a>{` - A network-wide DNS-based ad-blocking service`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.samba.org/"
        }}>{`Samba`}</a>{` - Exports filesystem directories natively supported by Windows, macOS and Linux`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sensu.io/"
        }}>{`Sensu Go`}</a>{` - A monitoring platform to schedule checks and metrics of my infrastructure`}</li>
    </ul>
    <h3>{`Why not docker?`}</h3>
    <p>{`When I starting working on the Ansible for my home server, I toyed with deploying services using docker containers.
The main reason I didn't continue down this path was because I disliked the apparent ease of grabbing the docker image
and running it.
It seems that the common process is to search `}<a parentName="p" {...{
        "href": "https://hub.docker.com/"
      }}>{`dockerhub`}</a>{` for the service you want to run and
just pick the first image (though the method to run the containers varies - docker compose, k3s, Kubernetes, etc).
My main dislike of this pattern is that although it's great when it's easy to get your services up and running, it
seemed as though I wouldn't get an understanding of how the service hung together this way.
If a service broke, I'd be a step behind being able to diagnose and fix the problem.`}</p>
    <p>{`I'm also not a huge fan of the `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/reference/builder/"
      }}>{`Dockerfile`}</a>{` format, especially after
using Ansible.
While it is possible to build docker containers using Ansible using tools like
`}<a parentName="p" {...{
        "href": "https://www.packer.io/docs/builders/docker"
      }}>{`Packer`}</a>{` (and in fact, I've used it to build some docker containers at work)
I didn't want to have a nightly docker container building infrastructure set up to be able to run my services to keep on
top of security updates.`}</p>
    <p>{`The last issue I took with docker (a fix for which I understand is in the pipeline) is the lack of UID mapping for
containers.
Root in a docker container is effectively root on the host, while with LXD, UIDs and GIDs are mapped to higher ranges so
root in an LXD container is actually UID 100000 on the host.`}</p>
    <p>{`Having said all this, a few years down the line, I'm far less against the idea.
Perhaps my next home server iteration will be docker-based, whether that's docker compose, k3s or some other setup.`}</p>
    <h3>{`Ansible`}</h3>
    <p>{`It has been great using Ansible to configure the server, it means that I can rebuild services very quickly, or run a
quick Ansible playbook to update config of several machines at the same time.`}</p>
    <p>{`For example; I realised that I'd left my Nginx TLS config at its default, so my two reverse proxies accepted TLS 1.0
connections (I don't take any card details through either of them so it doesn't really matter).
I updated my Nginx Ansible role to specify the TLS settings, then run the playbook, and all instances of Nginx that use
TLS are updated.
Of course I could have updated the settings manually - it's only two servers, right?
It would have involved updating 18 virtual host files - not to mention remembering to add them next time.`}</p>
    <p>{`Though, really the benefit of Ansible is more obvious at larger scales.
At work, I manage maybe 500 hosts, and about 400 of those have some version of MySQL installed on them.
We run quite an optimised stack, so occasionally I'll find a setting to tune to improve security, performance or
reliability or whatever.
Instead of logging into each of the 400 machines individually to tweak the setting one by one - boring! -
I would update the MySQL Ansible role to make the change (conditionally on other factors if applicable) and run the
update through some sort of test-stage-live process.`}</p>
    <p>{`The original idea for my home setup was to consider each container to be ephemeral, so the container could be deleted
and another quickly stood in its place and configured in the same way.
All Ansible tasks are written to update packages where available (unless a specific version is required) to make a
complete rebuild equivalent to updating the existing setup.
Unattended upgrades covers package updates between Ansible playbook runs where possible.
Monitoring is set up for packages which can't be updated through unattended upgrades.`}</p>
    <p>{`If I want to run a service I don't already have an Ansible play for, I'll write one.
On the whole, it's just Ansible-ifying the installation instructions for whatever service I want to run.
This is usually installing a package, writing config file, then starting a service.
I will often take the default config file, copy it into a template in the ansible play and edit it there.
I don't bother supporting all the various features of the service to be configured using Ansible unless I think it may
be beneficial - it can always be added a the point of use at a later time.`}</p>
    <h3>{`Example service setup`}</h3>
    <p>{`For example, say I want to add another Samba instance, this time accessible to the IoT network for some reason.`}</p>
    <p>{`First of all, I would assign the IP addresses in the network config in my Ansible inventory.`}</p>
    <p>{`Next, I can add an item to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lxdhost_containers`}</code>{` list to have the Ansible play create the new container on the right
networks:`}</p>
    <span className="prism-title">YAML</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`lxdhost_containers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` iot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`samba
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`network_config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`address`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _networks.home_lxdbr.ips['iot-samba.example.com'] }}"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`address`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _networks.iotbr.ips['iot-samba.example.com'] }}"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`netmask`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 255.255.255.0
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parent`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _iot_bridge }}"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`gateway`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` iot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`data
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /iot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`data
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`source`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /data/iot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`data`}</code></pre></div>
    <p>{`Running the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lxdhost`}</code>{` Ansible play against the home server with this config will create a new container called
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iot-samba`}</code>{`.
It'll be given two NICs and given the pre-configured static IPs.
It will also have the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/data/iot-data`}</code>{` directory mounted into it at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/iot-data`}</code>{`.
Though the use of `}<a parentName="p" {...{
        "href": "https://cloud-init.io/"
      }}>{`cloud-init`}</a>{`, the new container already has python installed, an admin user
with authorised SSH public keys pre-configured.`}</p>
    <p>{`Next, I can write an inventory file for this new machine to indicate it should have the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`samba`}</code>{` Ansible role run against
it, configure samba and the firewall ready for use (several other roles are always run against this type of machine):`}</p>
    <span className="prism-title">YAML</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`groups`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` samba

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`vars`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`firewall_open_ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` samba
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interface`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` eth1
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`protocol`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` udp
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`137`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` samba
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interface`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` eth1
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`protocol`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` udp
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`138`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` samba
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interface`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` eth1
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`protocol`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` tcp
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`139`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` samba
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interface`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` eth1
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`protocol`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` tcp
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`445`}</span>{`

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`samba_users`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mydevice

  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`samba_shares`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` iot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`data
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` IoT Data
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` /iot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`data
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`valid_users`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`mydevice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`The above config allows UDP ports 137 and 138, and TCP on ports 139 and 445 to be accessed on the eth1 interface
(eth0 is the LXD network interface).
A user will be created called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mydevice`}</code>{`, and a random password generated for it.
It is only able to login over Samba.`}</p>
    <p>{`Once the Ansible playbook has been run, the samba server will be configured and running.`}</p>
    <h3>{`Exposing services`}</h3>
    <p>{`To expose HTTP(S) services to the world, I have configured port-forwards on the host for ports 80 and 443 to my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`proxy`}</code>{`
container.`}</p>
    <span className="prism-title">YAML</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`firewall_enable_forwarding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`firewall_port_forwards`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` proxy http
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`in_interface`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _isp_vlan }}"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`in_port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`to_host`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _networks.home_lxdbr.ips['proxy.example.com'] }}"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`to_port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` proxy https
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`in_interface`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _isp_vlan }}"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`in_port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`to_host`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ _networks.home_lxdbr.ips['proxy.example.com'] }}"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`to_port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span></code></pre></div>
    <p>{`Then virtual hosts for the services can be generated on the proxy container, and letsencrypt certificates will be configured:`}</p>
    <span className="prism-title">YAML</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`nginx_use_letsencrypt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`nginx_vhosts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` gitea.example.com
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`state`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` present
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ssl_only`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`server_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` gitea.example.com
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`locations`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` proxy
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`backend`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://{{ _networks.home_lxdbr.ips['gitea.example.com'] }}:3000/"`}</span></code></pre></div>
    <h2>{`Backups`}</h2>
    <p>{`I use `}<a parentName="p" {...{
        "href": "https://restic.net/"
      }}>{`Restic`}</a>{` to take nightly off-site backups.`}</p>
    <h2>{`Warts`}</h2>
    <p>{`Not everything is wonderful with my home server/network setup.`}</p>
    <p>{`The worst offender that comes to mind is my lack of NAT loopback / hairpinning - where traffic originating from a NATed
network destined for the server's public IP address does not end up going to the server.
In theory, I should be able to add firewall rules to handle this traffic, but I have not found a way to fix this, and
I've spent enough time on it.`}</p>
    <p>{`Instead I added my reverse proxy container to my home network, and added DNS records to my Pihole server to hand out the
home network IP of the reverse proxy container.
This allows me to use the same hostname internally and not have to go though my NAT "properly".
I hope to fix this one day, but now that it's not broken I suspect it may stay like this for a while.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      